<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('osym_code')"
                        :isNewButton="checkPermission('osymcode_store')"
                        @new-button-click="add"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('osym_code')"
                              :isNewButton="checkPermission('osymcode_store')"
                              @new-button-click="add"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('code')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.code"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('program')">
                            <program-selectbox :faculty_code="datatable.queryParams.filter.faculty_code" major_type="A"
                                               v-model="datatable.queryParams.filter.program_code"></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('scholarship_rate')">
                            <scholarship-rate-selectbox v-model="datatable.queryParams.filter.scholarship_rate">
                            </scholarship-rate-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="modal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id == 0 ? "add" : "edit") }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('code')">
                                                <b-form-input v-model="form.code">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="faculty_code" rules="" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('faculty')">
                                                <faculty-selectbox v-model="form.faculty_code"></faculty-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="program_code" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('program')">
                                                <program-selectbox :faculty_code="form.faculty_code" major_type="A"
                                                                   v-model="form.program_code"></program-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="scholarship_rate" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('scholarship_rate')">
                                                <scholarship-rate-selectbox v-model="form.scholarship_rate">>
                                                </scholarship-rate-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                                {{ $t("save") | toUpperCase }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import OsymService from "@/services/OsymService";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ScholarshipRateSelectbox from "@/components/interactive-fields/ScholarshipRateSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        ScholarshipRateSelectbox,
        FacultySelectbox,
        ProgramSelectbox
    },
    metaInfo() {
        return {
            title: this.$t("osym_code"),
        };
    },
    data() {
        return {
            id: 0,
            form: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: "osymcode_update",
                                callback: (row) => {
                                    this.id = row.id;
                                    this.loadData();
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "osymcode_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("code"),
                        field: "code",
                        sortable: true,
                    },
                    {
                        label: this.$t("program_name"),
                        field: this.getLocaleField("program_name"),
                        sortable: false,
                    },
                    {
                        label: this.$t("scholarship_rate"),
                        field: "scholarship_rate",
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "program_code",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return OsymService.getAll(config)
                              .then((response) => {
                                  this.datatable.rows = response.data.data;
                                  this.datatable.total = response.data.pagination.total;
                              })
                              .finally(() => {
                                  this.datatable.isLoading = false;
                              });
        },
        loadData() {
            if (this.id > 0) {
                OsymService.get(this.id)
                           .then((response) => {
                               this.$refs.storeForm.reset();
                               this.form = response.data.data;
                               this.$refs.modal.$refs.commonModal.show();
                           })
                           .catch((error) => {
                               if (error.data.message) {
                                   this.$toast.error(this.$t("api." + error.data.message));
                               }
                           });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate();
            if (isValid) {
                if (this.id == 0) {
                    OsymService.store(this.form)
                               .then((response) => {
                                   this.$toast.success(this.$t("api." + response.data.message));
                               })
                               .catch((error) => {
                                   this.showErrors(error)
                               })
                }
                else {
                    OsymService.update(this.id, this.form)
                               .then((response) => {
                                   this.$toast.success(this.$t("api." + response.data.message));
                               })
                               .catch((error) => {
                                   this.showErrors(error)
                               })
                }
            }
        },
        showErrors(error) {
            if (error.status == 422) {

                if (error.data.errors.program_code) {
                    this.$refs.storeForm.errors.program_code.push(error.data.errors.program_code[0]);
                }

                if (error.data.errors.code) {
                    this.$refs.storeForm.errors.code.push(error.data.errors.code[0]);
                }
                if (error.data.errors.scholorship_rate) {
                    this.$refs.storeForm.errors.scholorship_rate.push(error.data.errors.scholorship_rate[0]);
                }
            }
            else if (error.status == 406) {
                this.$toast.error(this.$t('api.' + error.data.message));
            }
        },
        delete(id) {
            OsymService.deleteOsym(id).then((response) => {
                this.filter();
                this.$toast.success(this.$t("api." + response.data.message));
            }).catch((error) => {
                this.$toast.error(this.$t("api." + error.data.message));
            });
        },
        add() {
            this.id = 0;
            this.$refs.storeForm.reset();
            this.form = {};
            this.$refs.modal.$refs.commonModal.show();
        },
    },
};
</script>

